@import 'utilities';

body.duaModal-on {
    overflow: hidden;
}
.duaModal {
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: none;

    .duaModal-backdrop {
        width: 100%;
        min-height: 100%;
        background-color: rgba($black, 40%);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        @include flex;
        @include align-items-center;
        @include justify-content-center;

        &.duaModal-backdrop-pointer {
            cursor: pointer;
        }
        .duaModal-content {
            background-color: $white;
            cursor: auto;
            position: relative;
            @include flex-grow(0);
            @include flex-shrink(0);

            &.duaModal-content-fullscreen {
                width: 100%;
                min-height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
            .duaModal-close {
                width: 48px;
                height: 48px;
                @include btn-lnk;
                @include lnk($black);
                @include icon;
                font-size: 24px;
                position: absolute;
                top: 0;
                right: 0;
                @include inline-flex;
                @include align-items-center;
                @include justify-content-center;

                &:before {
                    content: "\ed93";
                }
            }
            .duaModal-title {
                @include text-h14;
                font-weight: 700;
            }
            .duaModal-text {
                @include text-p4;
            }
            .duaModal-prompt {
                @include form-group;
            }
            .duaModal-bottom {
                margin-top: 20px;
                @include flex;

                button {
                    @include btn;

                    + button {
                        margin-left: 12px;
                    }
                    &.duaModal-bottom-ok {
                        @include btn-n1;
                    }
                    &.duaModal-bottom-ko {
                        @include btn-n2;
                    }
                }
            }
        }
    }
}
@media ($mediaFromTabletV) {
    .duaModal {

        .duaModal-backdrop {
            padding: 20px;

            .duaModal-content {
                padding: 40px;
                border-radius: 4px;
                box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
                @include flex-basis(540px);

                &.duaModal-content-small {
                    @include flex-basis(360px);
                }
                &.duaModal-content-large {
                    @include flex-basis(720px);
                }
                &.duaModal-content-fullscreen {
                    border-radius: 0;
                }
                .duaModal-title {
                    margin-bottom: 12px;
                }
                .duaModal-bottom {
                    @include justify-content-end;
                }
            }
        }
    }
}
@media ($mediaMobile) {
    .duaModal {

        .duaModal-backdrop {

            .duaModal-content {
                width: 100%;
                padding: 20px;
                @include flex-basis(100%);

                .duaModal-title {
                    margin-bottom: 8px;
                }
                .duaModal-bottom {

                    button {
                        @include btn-block;
                    }
                }
            }
        }
    }
}