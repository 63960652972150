@import 'utilities';

// flex
.d-f {
    @include flex;
}
.d-if {
    @include inline-flex;
}
.ai-c {
    @include align-items-center;
}
.ai-fs {
    @include align-items-start;
}
.ai-fe {
    @include align-items-end;
}
.jc-c {
    @include justify-content-center;
}
.jc-fs {
    @include justify-content-start;
}
.jc-fe {
    @include justify-content-end;
}
.jc-sa {
    @include justify-content-around;
}
.jc-sb {
    @include justify-content-between;
}
.jc-se {
    @include justify-content-evenly;
}

// margin
.mt-auto {
    margin-top: auto;
}
.mb-auto {
    margin-bottom: auto;
}
.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}
.mt-5 {
    margin-top: 5px;
}
.mb-5 {
    margin-bottom: 5px;
}
.ml-5 {
    margin-left: 5px;
}
.mr-5 {
    margin-right: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mb-10 {
    margin-bottom: 10px;
}
.ml-10 {
    margin-left: 10px;
}
.mr-10 {
    margin-right: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mb-15 {
    margin-bottom: 15px;
}
.ml-15 {
    margin-left: 15px;
}
.mr-15 {
    margin-right: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mb-20 {
    margin-bottom: 20px;
}
.ml-20 {
    margin-left: 20px;
}
.mr-20 {
    margin-right: 20px;
}
.mt-25 {
    margin-top: 25px;
}
.mb-25 {
    margin-bottom: 25px;
}
.ml-25 {
    margin-left: 25px;
}
.mr-25 {
    margin-right: 25px;
}
.mt-30 {
    margin-top: 30px;
}
.mb-30 {
    margin-bottom: 30px;
}
.ml-30 {
    margin-left: 30px;
}
.mr-30 {
    margin-right: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mb-40 {
    margin-bottom: 40px;
}
.ml-40 {
    margin-left: 40px;
}
.mr-40 {
    margin-right: 40px;
}