@import 'utilities';

* {
    font-family: inherit;
}
body {
    color: $black;
    font-family: var(--fontFamily);
    @include text-p3;
}
p, li {
    margin-bottom: 20px;
}

.section-tit1 {
    margin-bottom: 25px;
    font-weight: 700;

    @media ($mediaFromTabletV) {
        @include text-h9;
    }
    @media ($mediaMobile) {
        @include text-h13;
    }
}

// ESTILOS DE TEXTO

// color
.text-primary {
    color: $primary;
}
.text-secondary {
    color: $secondary;
}
.text-tertiary {
    color: $tertiary;
}
.text-black {
    color: $black;
}
.text-grey4 {
    color: $grey4;
}
.text-grey3 {
    color: $grey3;
}
.text-grey2 {
    color: $grey2;
}
.text-grey1 {
    color: $grey1;
}
.text-white {
    color: $white;
}
.text-error {
    color: $error;
}
.text-success {
    color: $success;
}
.text-warning {
    color: $warning;
}

// estilo
.text-italic {
    font-style: italic;
}

// grosor
.text-light {
    font-weight: 300;
}
.text-regular {
    font-weight: 400;
}
.text-medium {
    font-weight: 500;
}
.text-semibold {
    font-weight: 600;
}
.text-bold {
    font-weight: 700;
}
.text-extrabold {
    font-weight: 800;
}
.text-black {
    font-weight: 900;
}

// alineacion
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}

// decoracion
.text-underline {
    text-decoration: underline;
}
.text-linethrough {
    text-decoration: line-through;
}

// transformacion
.text-uppercase {
    text-transform: uppercase;
}


// CUERPOS DE TEXTO

// encabezado
.text-h1 {
    @include text-h1;
}
.text-h2 {
    @include text-h2;
}
.text-h3 {
    @include text-h3;
}
.text-h4 {
    @include text-h4;
}
.text-h5 {
    @include text-h5;
}
.text-h6 {
    @include text-h6;
}
.text-h7 {
    @include text-h7;
}
.text-h8 {
    @include text-h8;
}
.text-h9 {
    @include text-h9;
}
.text-h10 {
    @include text-h10;
}
.text-h11 {
    @include text-h11;
}
.text-h12 {
    @include text-h12;
}
.text-h13 {
    @include text-h13;
}
.text-h14 {
    @include text-h14;
}
.text-h15 {
    @include text-h15;
}
@media ($mediaFromTabletV) {
    .text-h1-sm {
        @include text-h1;
    }
    .text-h2-sm {
        @include text-h2;
    }
    .text-h3-sm {
        @include text-h3;
    }
    .text-h4-sm {
        @include text-h4;
    }
    .text-h5-sm {
        @include text-h5;
    }
    .text-h6-sm {
        @include text-h6;
    }
    .text-h7-sm {
        @include text-h7;
    }
    .text-h8-sm {
        @include text-h8;
    }
    .text-h9-sm {
        @include text-h9;
    }
    .text-h10-sm {
        @include text-h10;
    }
    .text-h11-sm {
        @include text-h11;
    }
    .text-h12-sm {
        @include text-h12;
    }
    .text-h13-sm {
        @include text-h13;
    }
    .text-h14-sm {
        @include text-h14;
    }
    .text-h15-sm {
        @include text-h15;
    }
}
@media ($mediaFromTabletH) {
    .text-h1-md {
        @include text-h1;
    }
    .text-h2-md {
        @include text-h2;
    }
    .text-h3-md {
        @include text-h3;
    }
    .text-h4-md {
        @include text-h4;
    }
    .text-h5-md {
        @include text-h5;
    }
    .text-h6-md {
        @include text-h6;
    }
    .text-h7-md {
        @include text-h7;
    }
    .text-h8-md {
        @include text-h8;
    }
    .text-h9-md {
        @include text-h9;
    }
    .text-h10-md {
        @include text-h10;
    }
    .text-h11-md {
        @include text-h11;
    }
    .text-h12-md {
        @include text-h12;
    }
    .text-h13-md {
        @include text-h13;
    }
    .text-h14-md {
        @include text-h14;
    }
    .text-h15-md {
        @include text-h15;
    }
}
@media ($mediaDesktop) {
    .text-h1-lg {
        @include text-h1;
    }
    .text-h2-lg {
        @include text-h2;
    }
    .text-h3-lg {
        @include text-h3;
    }
    .text-h4-lg {
        @include text-h4;
    }
    .text-h5-lg {
        @include text-h5;
    }
    .text-h6-lg {
        @include text-h6;
    }
    .text-h7-lg {
        @include text-h7;
    }
    .text-h8-lg {
        @include text-h8;
    }
    .text-h9-lg {
        @include text-h9;
    }
    .text-h10-lg {
        @include text-h10;
    }
    .text-h11-lg {
        @include text-h11;
    }
    .text-h12-lg {
        @include text-h12;
    }
    .text-h13-lg {
        @include text-h13;
    }
    .text-h14-lg {
        @include text-h14;
    }
    .text-h15-lg {
        @include text-h15;
    }
}

// parrafo
.text-p1 {
    @include text-p1;
}
.text-p2 {
    @include text-p2;
}
.text-p3 {
    @include text-p3;
}
.text-p4 {
    @include text-p4;
}
.text-p5 {
    @include text-p5;
}
@media ($mediaFromTabletV) {
    .text-p1-sm {
        @include text-p1;
    }
    .text-p2-sm {
        @include text-p2;
    }
    .text-p3-sm {
        @include text-p3;
    }
    .text-p4-sm {
        @include text-p4;
    }
    .text-p5-sm {
        @include text-p5;
    }
}
@media ($mediaFromTabletH) {
    .text-p1-md {
        @include text-p1;
    }
    .text-p2-md {
        @include text-p2;
    }
    .text-p3-md {
        @include text-p3;
    }
    .text-p4-md {
        @include text-p4;
    }
    .text-p5-md {
        @include text-p5;
    }
}
@media ($mediaDesktop) {
    .text-p1-lg {
        @include text-p1;
    }
    .text-p2-lg {
        @include text-p2;
    }
    .text-p3-lg {
        @include text-p3;
    }
    .text-p4-lg {
        @include text-p4;
    }
    .text-p5-lg {
        @include text-p5;
    }
}