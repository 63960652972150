@import 'utilities';

picture,
picture img,
.img-responsive {
    display: block;
}
picture img,
.img-responsive {
    max-width: 100%;
}
.img-cover {
    @include imageCover;
}

.embed-responsive {
    @include embedResponsive(16, 9);
}