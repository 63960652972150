@import
'reset',
'layout',
'tipography',
'icons',
// 'icons-set',
'links',
// 'forms',
'modal',
'multimedia',
'tags',
'aux-classes',
'tables',
// modified from material
'mat-select';

.main-container {
    overflow: hidden;
}

.section-tit1 {
    margin-bottom: 50px;
    color: $primary;
    font-family: $secondaryFont;
    font-weight: 400;
    text-transform: uppercase;

    &:after {
        content: '';
        width: 140px;
        height: 6px;
        background-color: $secondary;
        display: block;
    }
    @media ($mediaDesktop) {
        @include text-h8;
    }
    @media ($mediaTabletX) {
        @include text-h10;
    }
    @media ($mediaMobile) {
        @include text-h12;
    }
}
.section-tit2 {
    margin-bottom: 20px;
    color: $primary;
    font-weight: 700;
    text-transform: uppercase;

    @media ($mediaDesktop) {
        @include text-h12;
    }
    @media ($mediaToTabletH) {
        @include text-h14;
    }
}
.isotipo-stroke {

    &:before {
        content: '';
        width: 30px;
        height: 35px;
        @include mask('~src/assets/images/icons/isotipo-stroke.svg');
        background-color: $secondary;
        display: block;
    }
}
.isotipo-triple {
    
    &:before {
        content: '';
        margin-bottom: 20px;
        background-image: url('~src/assets/images/icons/isotipo-triple.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
    }
    @media ($mediaFromTabletV) {
        &:before {
            width: 123px;
            height: 44px;
        }
    }
    @media ($mediaMobile) {
        &:before {
            width: 110px;
            height: 39px;
        }
    }
}