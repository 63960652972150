@import 'utilities';

// @font-face {
//     font-family: 'icomoon';
//     src: url('./icons/icomoon.woff2') format('woff2');
//     // src:  url('icons/icomoon.eot?dc2w8u');
//     // src:  url('icons/icomoon.eot?dc2w8u#iefix') format('embedded-opentype'),
//     //     url('icons/icomoon.ttf?dc2w8u') format('truetype'),
//     //     url('icons/icomoon.woff?dc2w8u') format('woff'),
//     //     url('icons/icomoon.svg?dc2w8u#icomoon') format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: block;
// }
// [class^="icon-"], [class*=" icon-"] {
//     @include icon;
// }
.icon-12 {
    font-size: 12px;
}
.icon-16 {
    font-size: 16px;
}
.icon-20 {
    font-size: 20px;
}
.icon-24 {
    font-size: 24px;
}
.icon-28 {
    font-size: 28px;
}