@mixin form-label {
    margin-bottom: 2px;
    color: $black;
    @include text-p5;
    font-weight: 400;
    text-align: left;
    display: block;

    span {
        color: $grey3;
    }
}
@mixin form-control {
    width: 100%;
    min-width: 0;
    height: 40px;
    padding: 9px 12px;
    border: 1px solid $grey3;
    border-radius: $formControlBorderRadius;
    color: $black;
    @include text-p3;
    font-weight: 400;
    resize: none;
    display: block;

    &:hover {
        border-color: $primary;
    }
    &::-webkit-input-placeholder {
        color: $grey3;
        opacity: 1;
    }
    &::-ms-input-placeholder {
        color: $grey3;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: $grey3;
        opacity: 1;
    }
    &::-moz-placeholder {
        color: $grey3;
        opacity: 1;
    }
    &:-moz-placeholder {
        color: $grey3;
        opacity: 1;
    }
    &::placeholder {
        color: $grey3;
        opacity: 1;
    }
}
@mixin form-select {
    position: relative;

    &:before {
        content: "\ef96";
        @include icon;
        font-size: 20px;
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
    }
}
@mixin form-file {

    input[type='file'] {
        display: none;
    }
    @media ($mediaMobile) {
        .input-group {
            @include flex-column;
            @include align-items-start;

            .form-control {
                width: 100%;
            }
            .btn {
                margin-top: 6px;
                @include flex-order(2);
            }
        }
    }
}
@mixin form-help {
    margin-top: 2px;
    color: $black;
    @include text-p5;
    font-weight: 300;
    font-style: italic;
}
@mixin form-group {
    margin-bottom: 28px;

    &:not(.form-group-static) {
        position: relative;

        .control-label {
            margin-left: 13px;
            margin-bottom: 0;
            pointer-events: none;
            position: absolute;
            z-index: 5;
            @include transition(all 0.2s);
        }
        .help-block {
            position: absolute;
            z-index: 5;
            top: 100%;
            left: 0;
        }
        &:not(.form-group-focused):not(.form-group-filled) {

            .control-label {
                @include text-p3;
                line-height: 40px;
            }
        }
        &.form-group-focused,
        &.form-group-filled {

            .control-label {
                max-width: calc(100% - 24px);
                margin-left: 8px;
                padding: 0 4px;
                background-color: white;
                transform: translateY(-50%);
            }
        }
        &.form-group-filled {

            .control-label {
                color: $grey3;
            }
        }
    }
    &.form-group-focused {

        .control-label {
            color: $primary;
        }
        .form-control {
            border-color: $primary;
            box-shadow: 0 0 0 1px $primary inset;
        }
    }
    // &.has-success {

    //     .control-label,
    //     .help-block {
    //         color: $success!important
    //     }
    //     .form-control {
    //         border-color: $success!important;
    //     }
    //     &.form-group-focused {

    //         .form-control {
    //             box-shadow: 0 0 0 1px $success inset!important;
    //         }
    //     }
    // }
    &.has-error {

        .control-label,
        .form-checkbox,
        .form-radio,
        .form-checkbox a,
        .form-radio a,
        .help-block {
            color: $error!important;
        }
        .form-control {
            border-color: $error!important;
        }
        &.form-group-focused {

            .form-control {
                box-shadow: 0 0 0 1px $error inset!important;
            }
        }
    }
}
@mixin input-group {
    @include flex;

    > * {
        border-radius: $formControlBorderRadius!important;

        &:first-child {
            border-top-left-radius: $formControlBorderRadius!important;
            border-bottom-left-radius: $formControlBorderRadius!important;
        }
        &:last-child {
            border-top-right-radius: $formControlBorderRadius!important;
            border-bottom-right-radius: $formControlBorderRadius!important;
        }
    }
    .form-control {
        @include flex-grow(1);
        @include flex-shrink(1);
        @include flex-basis(auto);
    }
    .btn {
        @include flex-grow(0);
        @include flex-shrink(0);
        @include flex-basis(auto);
    }
    .input-group-addon {
        min-width: 40px;
        padding: 0 9px;
        border: 1px solid $grey3;
        @include flex-grow(0);
        @include flex-shrink(0);
        @include inline-flex;
        @include align-items-center;

        &.input-group-addon-black {
            border-color: transparent;
            background-color: $black;
            color: $white;
        }
    }
}
@mixin form-check-radio {
    @include flex;
    @include align-items-center;

    > * {

        + * {
            margin-left: 4px;
        }
    }
    input {
        @include icon;
        font-size: 20px;
        cursor: pointer;
        @include flex-grow(0);
        @include flex-shrink(0);
        @include flex-basis(auto);
        @include appearance-none;

        &[type='checkbox'] {
            
            &:before {
                content: "\edb2";
            }
            &:checked {
                
                &:before {
                    content: "\edb6";
                }
            }
        }
        &[type='radio'] {

            &:before {
                content: "\ea3b";
            }
            &:checked {
                content: "\ea3c";
            }
        }
    }
    label {
        @include text-p5;
        font-weight: 400;
        cursor: pointer;
    }
    + .help-block {
        display: none!important;
    }
}