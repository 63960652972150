@import 'utilities';

:root {
    --viewportWidth: 100vw;
    --viewportHeight: 100vh;
}

body {
    min-height: 100vh;
    @include flex;
    @include flex-column;
}
.mainContent {
    @include flex-grow(1);
    overflow: hidden;

    &.paddingBottom {
        padding-bottom: 60px;
    }
}
@media ($mediaDesktop) {
    * + .mainContent {

        &.paddingTop {
            padding-top: 70px;
        }
    }
}
@media ($mediaTabletX) {
    * + .mainContent {
        
        &.paddingTop {
            padding-top: 55px;
        }
    }
}
@media ($mediaMobile) {
    * + .mainContent {
        
        &.paddingTop {
            padding-top: 40px;
        }
    }
}

.center-block {
    @include center-block;
}

// CONTAINER
.container-fluid {
    @include container;
}
.container {
    @include container-lg;
    @include container-md;
    @include container-sm;
    @include container-xs;
}
// ROW
.row {
    @include row(16px);
}
// GRID
.grid {
    @include grid(16px);
}

@for $i from 1 through 12 {
    // ROW
    .col-#{$i} {
        @include col($i);
    }
    .inset-#{$i} {
        @include inset($i);
    }
    .offset-#{$i} {
        @include offset($i);
    }
    // GRID
    .grid-cols-#{$i} {
        @include gridCols($i);
    }
    .grid-rows-#{$i} {
        @include gridRows($i);
    }
    .grid-colspan-#{$i} {
        @include gridColSpan($i);
    }
    .grid-rowspan-#{$i} {
        @include gridRowSpan($i);
    }
}
// tablet vertical
@media ($mediaFromTabletV) {
    @for $i from 1 through 12 {
        // ROW
        .col-sm-#{$i} {
            @include col($i);
        }
        .inset-sm-#{$i} {
            @include inset($i);
        }
        .offset-sm-#{$i} {
            @include offset($i);
        }
        // GRID
        .grid-cols-sm-#{$i} {
            @include gridCols($i);
        }
        .grid-rows-sm-#{$i} {
            @include gridRows($i);
        }
        .grid-colspan-sm-#{$i} {
            @include gridColSpan($i);
        }
        .grid-rowspan-sm-#{$i} {
            @include gridRowSpan($i);
        }
    }
}
// tablet horizontal
@media ($mediaFromTabletH) {
    @for $i from 1 through 12 {
        // ROW
        .col-md-#{$i} {
            @include col($i);
        }
        .inset-md-#{$i} {
            @include inset($i);
        }
        .offset-md-#{$i} {
            @include offset($i);
        }
        // GRID
        .grid-cols-md-#{$i} {
            @include gridCols($i);
        }
        .grid-rows-md-#{$i} {
            @include gridRows($i);
        }
        .grid-colspan-md-#{$i} {
            @include gridColSpan($i);
        }
        .grid-rowspan-md-#{$i} {
            @include gridRowSpan($i);
        }
    }
}
// desktop
@media ($mediaDesktop) {
    @for $i from 1 through 12 {
        // ROW
        .col-lg-#{$i} {
            @include col($i);
        }
        .inset-lg-#{$i} {
            @include inset($i);
        }
        .offset-lg-#{$i} {
            @include offset($i);
        }
        // GRID
        .grid-cols-lg-#{$i} {
            @include gridCols($i);
        }
        .grid-rows-lg-#{$i} {
            @include gridRows($i);
        }
        .grid-colspan-lg-#{$i} {
            @include gridColSpan($i);
        }
        .grid-rowspan-lg-#{$i} {
            @include gridRowSpan($i);
        }
    }
}