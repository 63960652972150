@use "sass:math";

@mixin imageContain {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    object-position: center;
}
@mixin imageCover {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
}

@mixin mask($url, $size: contain, $position: center) {
    mask-image: url($url);
    mask-size: $size;
    mask-repeat: no-repeat;
    mask-position: $position;
}

@mixin embedResponsive($width, $height) {
    height: 0;
    padding-bottom: math.div(100% * $height, $width);
    position: relative;

    > * {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}