@use '~@angular/material' as mat;
$primaryFont: 'Plex Sans Condensed', sans-serif;
$primaryFontPublic : 'Nunito', sans-serif;
$secondaryFontPublic:  'Bebas Neue', cursive;
$font-family: $primaryFont;
$materialIconFont: 'Material Icons';

$navbar-font-family: $primaryFont;
$sidebar-font-family: $primaryFont;

//TODO: REVISAR SI COMPENSA USAR display-1 display-2... para tener varios estilos de una fuente usando clases de neoMaterial
// Ej: .mat-h1, mat-body-1
//Define sobreescritura estilos fuente por defecto de angular neoMaterial
$primaryFontMaterial: mat.define-typography-config(
  $font-family: $primaryFont,
  $display-4: mat.define-typography-level(112px, 112px, 900, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(56px, 56px, 800, $letter-spacing: -0.02em),
  $display-2: mat.define-typography-level(45px, 48px, 700, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(34px, 40px, 600),
  $headline: mat.define-typography-level(26px, 36px, 900),
  $title: mat.define-typography-level(24px, 34px, 800),
  $subheading-2: mat.define-typography-level(22px, 32px, 700),
  $subheading-1: mat.define-typography-level(20px, 30px, 700),
  $body-2: mat.define-typography-level(18px, 28px, 700),
  $body-1: mat.define-typography-level(16px, 26px, 600),
  $caption: mat.define-typography-level(14px, 22px, 400)
);
