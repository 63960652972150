//FLEX
@mixin flex {
    display: -webkit-flex;
    display: flex;
}
@mixin inline-flex {
    display: -webkit-inline-flex;
    display: inline-flex;
}
@mixin flex-row {
    -webkit-flex-direction: row;
    flex-direction: row;
}
@mixin flex-row-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
@mixin flex-column {
    -webkit-flex-direction: column;
    flex-direction: column;
}
@mixin flex-column-reverse {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
}
@mixin flex-wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
@mixin flex-grow($i) {
    -webkit-flex-grow: $i;
    flex-grow: $i;
}
@mixin flex-shrink($i) {
    -webkit-flex-shrink: $i;
    flex-shrink: $i;
}
@mixin flex-basis($i) {
    -webkit-flex-basis: $i;
    flex-basis: $i;
}
// GRID
@mixin grid($colGap: false, $rowGap: false, $cols: false, $rows: false) {
    display: -ms-grid;
    display: grid;

    @if $colGap {
        @if $rowGap {
            @include gridGap($colGap, $rowGap);
        } @else {
            @include gridGap($colGap);
        }
    }
    @if $cols {
        @include gridCols($cols);
    }
    @if $rows {
        @include gridRows($rows);
    }
}
@mixin gridGap($colGap, $rowGap: false) {
    @if $rowGap {
        grid-gap: #{$rowGap} #{$colGap};
    } @else {
        grid-gap: #{$colGap};
    }
}
@mixin gridCols($i) {
    grid-template-columns: repeat($i, 1fr);
}
@mixin gridRows($rows) {
    grid-template-rows: repeat($rows, 1fr);
}
@mixin gridColSpan($span) {
    grid-column: span $span / span $span;
}
@mixin gridRowSpan($span) {
    grid-row: span $span / span $span;
}
// COMMON
@mixin flex-order($i) { // cambiar en algun momento nomenclatura
    -webkit-order: $i;
    order: $i;
}
@mixin align-items-stretch {
    -webkit-align-items: stretch;
    align-items: stretch;
}
@mixin align-items-start {
    -webkit-align-items: flex-start;
    align-items: flex-start;
}
@mixin align-items-center {
    -webkit-align-items: center;
    align-items: center;
}
@mixin align-items-end {
    -webkit-align-items: flex-end;
    align-items: flex-end;
}
@mixin align-self-stretch {
    -webkit-align-self: stretch;
    align-self: stretch;
}
@mixin align-self-start {
    -webkit-align-self: flex-start;
    align-self: flex-start;
}
@mixin align-self-center {
    -webkit-align-self: center;
    align-self: center;
}
@mixin align-self-end {
    -webkit-align-self: flex-end;
    align-self: flex-end;
}
@mixin justify-content-start {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
}
@mixin justify-content-center {
    -webkit-justify-content: center;
    justify-content: center;
}
@mixin justify-content-end {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}
@mixin justify-content-around {
    -webkit-justify-content: space-around;
    justify-content: space-around;
}
@mixin justify-content-between {
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
@mixin justify-content-evenly {
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
}
@mixin justify-self-stretch {
    -webkit-justify-self: stretch;
    justify-self: stretch;
}
@mixin justify-self-start {
    -webkit-justify-self: flex-start;
    justify-self: flex-start;
}
@mixin justify-self-center {
    -webkit-justify-self: center;
    justify-self: center;
}
@mixin justify-self-end {
    -webkit-justify-self: flex-end;
    justify-self: flex-end;
}