// LINKS
@mixin lnk($color, $hover: false, $focus: false) {
    color: $color;

    &:focus {
        @if ($focus) {
            color: $focus;
        } @else {
            color: mix($color, white, 80%);
        }
    }
    &:hover {
        @if ($hover) {
            color: $hover;
        } @else {
            color: mix($color, white, 60%);
        }
    }
}
@mixin lnk-icon {
    @include inline-flex;
    @include align-items-center;

    > * + * {
        margin-left: 5px;
    }
}
@mixin lnk-icon-circle {
    @include lnk-icon;
    @include text-p3;

    > * + * {
        margin-left: 8px;
    }
    [class^="icon-"] {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: mix($primary, white, 60%);
        color: $white;
        @include inline-flex;
        @include align-items-center;
        @include justify-content-center;
    }
}

// BUTTONS
@mixin btn {
    height: 40px;
    padding: 0 20px;
    border: 1px solid;
    border-radius: $btnBorderRadius;
    font-family: $primaryFont;
    @include text-p3;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    @include inline-flex;
    @include align-items-center;
    @include justify-content-center;

    &[disabled] {
        opacity: 0.2;
    }
    > * + * {
        margin-left: 5px;
    }
}
@mixin btn-block {
    width: 100%;
    @include flex;
}
@mixin btn-icon {
    width: 40px;
    padding: 0;
}
@mixin btn-contained($background, $color: $white, $focus: true) {
    border-color: $background;
    background-color: $background;
    color: $color;
    box-shadow: 4px 5px $black;

    @if ($focus) {
        &:focus {

            &:after {
                content: '';
                border: 1px solid $color;
                border-radius: inherit;
                position: absolute;
                top: 1px;
                bottom: 1px;
                left: 1px;
                right: 1px;
            }
        }
    }
}
@mixin btn-outlined($color, $background: transparent, $focus: true) {
    border-color: $color;
    background-color: $background;
    color: $color;

    @if ($focus) {
        &:focus {
            
            &:after {
                content: '';
                border: 1px solid $color;
                border-radius: inherit;
                position: absolute;
                top: 1px;
                bottom: 1px;
                left: 1px;
                right: 1px;
            }
        }
    }
}
@mixin btn-invisible($color, $padding: false) {
    @if not ($padding) {
        padding-left: 0;
        padding-right: 0;
    }
    border-color: transparent;
    background-color: transparent;
    color: $color;

    &:focus,
    &:hover {
        color: $color;

        span {
            text-decoration: underline;
        }
    }
}
@mixin btn-n1 {
    @include btn-contained($primary, $white);

    &:hover {
        @include btn-outlined($primary, $white, false);
    }
}
@mixin btn-n2 {
    @include btn-contained($tertiary, $white);

    &:hover {
        @include btn-outlined($tertiary, $white, false);
    }
}

// BUTTON-LINK
@mixin btn-lnk {
    padding: 0;
    border: 0;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
}