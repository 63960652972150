// BACK
/*                [1. Core, dependencias]                          */
@import "../../../node_modules/@busacca/ng-pick-datetime/assets/style/picker.min.css";
/*                [2. Fuentes]                          */
@import "../fonts/plex-sans-condensed.css";
@import '~material-icons/iconfont/material-icons.css'; //sólo para iconos de neoMaterial
@import '../fonts/icons-matrix.css'; //iconos Matrix
@import '../fonts/icomoon.css'; //iconos previos a migración iconos matrix
 /*                [3. Calendario]                          */
 @import "../../../node_modules/angular-calendar/css/angular-calendar.css";
.main {
/*                [3. Utilities]                          */
// @import "utilities";
@import "variables/variables";

@import 'mixins/flex';
/*@import 'mixins/forms';*/
@import 'mixins/links';
@import 'mixins/layout';
@import 'mixins/other';
@import 'mixins/tipography';
@import 'mixins/campos';

@import "reset";
@import "layout";
@import "tipography";
//TODO: Revisar diferencias entre links y links_matrix
@import "links_matrix";
//@import "links";
/*@import "forms";
@import "modals";*/
@import "tags";
@import "aux-clases";
/*@import "tables";*/
@import "flex";

/*                [4. Angular Material]                          */
@import "angular-material";

/* TODO: REPLANTEAR, estas reglas estaban en tipography.scss aplicándose al body pero tras añadir la clase main al body para solventar
 * la problemática de la sobreescritura de los scss de neoMaterial custom sobre los scss de neoMaterial de node_modules evitando el uso de !important,
 * es una de las opciones para tener que añadir una única vez la clase .main como padre del resto de importaciones en vez
 * de hacerlo en cada uno de los scss que se importan en este archivo
 */
margin: 0;
background-color: $grey5;
color: $black;
font-family: $primaryFont;
@include text-p5;
font-weight: 400;

//TODO: REVISAR PARA QUE NO HAYA QUE SOBREESCRIBIR (header.component.html span hermano de ngx-avatar)
button {
  font-family:$primaryFont!important;
}
.cdk-overlay-container {
  .dialogoNuevaEntradaReserva {
    .mat-dialog-container {
      width: 900px;
    }
  }
}
}


// FRONT
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.mainFront {
  @import 'theme-front/theme';
  
  margin: 0;
  color: $tertiary;
  font-family: $primaryFont;
  @include text-p3;

  neo-pages {
    min-height: 100vh;
    @include flex;
    @include flex-column;
  }

  .mat-warn {
    color: $error;
  }
  .mat-menu-duacode  {

    &.mat-menu-tabla {
      border-radius: 4px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      background-color: $white;
    }
  }

  neo-button {

    button {

      &.mat-button-base {

        &.mat-primary {
          @include btn;
          @include btn-contained($primary);

          &:hover {
            @include btn-outlined($primary, $white);
          }
        }
        &.mat-accent {
          @include btn;
          @include btn-contained($secondary, $black);

          &:hover {
            @include btn-outlined($secondary, $white);
          }
        }
      }
    }
    &.button-block {
      
      button {
        @include btn-block;
      }
    }
  }

  @import "angular-material/mat-form-field";
  mat-form-field {
    margin-bottom: 28px;

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
      margin: 0 !important;

      .mat-form-field-flex {
        padding: 0 12px;

        .mat-form-field-outline {

          .mat-form-field-outline-start {
            border-radius: 0
          }
          .mat-form-field-outline-end {
            border-radius: 0
          }
        }
        .mat-form-field-infix {

          .mat-select {

            .mat-select-arrow-wrapper {
              transform: none;

              &:before {
                content: "\ef96";
                @include icon;
                font-size: 20px;
              }
              .mat-select-arrow {
                display: none;
              }
            }
          }
          .captcha {
            border-radius: 0;
            background-color: $black;

            neo-button {
              @include flex-order(1);
            }
          }
        }
        .mat-form-field-suffix {

          > .mat-icon {
            padding-top: 0;
            padding-right: 0;
            top: 0;
          }
        }
      }
      .mat-form-field-subscript-wrapper {
        padding: 0;
        top: calc(100% + 2px);
    
        .mat-error {
          @include text-p5;
          font-weight: 300;
          font-style: italic;
        }
      }
    }
    &.mat-form-field-disabled {

      .mat-form-field-wrapper {
  
        .mat-form-field-flex {
  
          .mat-form-field-infix {
  
            .mat-form-field-label-wrapper {

              .mat-form-field-label {
                color: rgba(33, 36, 41, 0.38);
              }
            }
            .mat-select {
  
              .mat-select-arrow-wrapper {
  
                &:before {
                  color: $grey3;
                }
              }
            }
          }
        }
      }
    }
    &.mat-form-field-appearance-outline {

      &.mat-form-field-can-float {

        &.mat-form-field-should-float {
  
          .mat-form-field-label {
            transform: translateY(-27px) scale(0.75);
          }
        }
      }
    }
  }
  mat-checkbox {
    display: block;

    .mat-checkbox-layout {
      @include flex;
      @include align-items-center;

      .mat-checkbox-inner-container {
        width: auto;
        height: auto;
        margin: 0;
        margin-right: 4px;

        > * {
          display: none;
        }
        &:before {
          content: "\edb2";
          @include icon;
          font-size: 20px;
          display: block;
        }
      }
      .mat-checkbox-label {
        @include text-p5;
        font-weight: 400;
      }
    }
    &.mat-checkbox-checked {

      .mat-checkbox-layout {

        .mat-checkbox-inner-container {

          &:before {
            content: "\edb6";
          }
        }
      }
    }
  }
  .mat-radio-button {

    .mat-radio-label {
      height: 40px;

      .mat-radio-container {

        > * {
          display: none;
        }
        &:before {
          content: "\ea3b";
          @include icon;
          font-size: 20px;
          display: block;
        }
      }
    }
    &.mat-radio-disabled {
      opacity: 0.3;
    }
    &.mat-radio-checked {

      .mat-radio-label {

        .mat-radio-container {
  
          &:before {
            content: "\ea3c";
            color: $primary;
          }
        }
      }
    }
  }
  .mat-radio-group {
    margin-left: -20px!important;
    margin-right: -20px!important;

    .mat-radio-button {
      margin-left: 20px!important;
      margin-right: 20px!important;
    }
  }
  .form-campo-radio {

    .field-radio {
      padding-left: 0!important;
      padding-right: 0!important;
      margin-bottom: 25px!important;

      .form-campo-radio-label {
          margin-bottom: 10px;
          @include text-p2;
          font-weight: 700;
      }
    }
  }

  @import "angular-material/mat-dialog";
  .cdk-overlay-container {

    .cdk-overlay-backdrop {
      background-color: rgba($black, 40%);
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
    }
    .cdk-global-overlay-wrapper {
      pointer-events: auto;
      overflow: auto;

      .cdk-overlay-pane {

        .mat-dialog-container {
          border-radius: 0;
          box-shadow: none;
          color: $black;
          @include text-p4;
          position: relative;
      
          .mat-dialog-content {
            max-height: none;
            padding: 0;
            margin: 0;
            overflow: visible;
      
            .header-dialog {
              padding-bottom: 0;
              margin-bottom: 14px;
              position: static;
              
              &:after {
                display: none;
              }
              .mat-dialog-title {
                @include text-h14;
                font-weight: 700;
              }
              .mat-dialog-actions {
                padding: 0;
                top: 0;
                right: 0;
      
                .mat-mini-fab {
                  width: 48px;
                  height: 48px;
                  color: $black;
                }
              }
            }
          }
        }
      }
    }
    @media ($mediaFromTabletV) {
      .cdk-global-overlay-wrapper {
  
        .cdk-overlay-pane {
  
          .mat-dialog-container {
            padding: 40px;
          }
        }
      }
      .cdk-overlay-pane:not(.dialogoInformacionUE) {
        max-width: 515px!important;
      }
      .dialogoInformacionUE {
        width: 700px!important;
        max-width: 100%!important;
      }
    }
    @media ($mediaMobile) {
      .cdk-global-overlay-wrapper {
  
        .cdk-overlay-pane {
          max-width: 360px!important;
  
          .mat-dialog-container {
            padding: 20px;
          }
        }
      }
    }
  }

  #cdk-describedby-message-container {
    display: none;
  }
}
