@import 'utilities';

*, *:before, *:after {
    padding: 0;
    border: 0;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    @include border-box;
    @include appearance-none
}