@import 'utilities';

a {
    @include lnk($black);
    cursor: pointer;
    text-decoration: none;
}
.lnk-primary {
    @include lnk($primary);
}
.lnk-secondary {
    @include lnk($secondary);
}
.lnk-tertiary {
    @include lnk($tertiary);
}
.lnk-black {
    @include lnk($black);
}
.lnk-grey4 {
    @include lnk($grey4);
}
.lnk-grey3 {
    @include lnk($grey3);
}
.lnk-grey2 {
    @include lnk($grey2);
}
.lnk-grey1 {
    @include lnk($grey1);
}
.lnk-white {
    @include lnk($white);
}
.lnk-icon {
    @include lnk-icon;
}
.lnk-icon-circle {
    @include lnk-icon-circle;
}

// buttons
.btn {
    @include btn;

    &.btn-block {
        @include btn-block;
    }
    &.btn-icon {
        @include btn-icon;
    }

    &.btn-n1 {
        @include btn-n1;
    }
    &.btn-n2 {
        @include btn-n2;
    }

    &.btn-primary {
        @include btn-contained($primary, $white);

        &:hover {
            @include btn-outlined($primary, $white, false);
        }
    }
    &.btn-primary-outlined {
        @include btn-outlined($primary, $white);

        &:hover {
            @include btn-contained($primary, $white, false);
        }
    }
    &.btn-primary-invisible {
        @include btn-invisible($primary);
    }
    &.btn-secondary {
        @include btn-contained($secondary, $black);

        &:hover {
            @include btn-outlined($secondary, $white, false);
        }
    }
    &.btn-secondary-outlined {
        @include btn-outlined($secondary, $white);

        &:hover {
            @include btn-contained($secondary, $white, false);
        }
    }
    &.btn-secondary-invisible {
        @include btn-invisible($secondary);
    }
    &.btn-tertiary {
        @include btn-contained($tertiary, $white);

        &:hover {
            @include btn-outlined($tertiary, transparent, false);
        }
    }
    &.btn-tertiary-outlined {
        @include btn-outlined($tertiary, $white);

        &:hover {
            @include btn-contained($tertiary, $white, false);
        }
    }
    &.btn-tertiary-invisible {
        @include btn-invisible($tertiary);
    }
    &.btn-black {
        @include btn-contained($black, $white);

        &:hover {
            @include btn-outlined($black, $white, false);
        }
    }
    &.btn-black-outlined {
        @include btn-outlined($black, $white);

        &:hover {
            @include btn-contained($black, $white, false);
        }
    }
    &.btn-black-invisible {
        @include btn-invisible($black);
    }
    &.btn-white {
        @include btn-contained($white, $black);

        &:hover {
            @include btn-outlined($black, $white, false);
        }
    }
    &.btn-white-outlined {
        @include btn-outlined($white);

        &:hover {
            @include btn-contained($white, $black, false);
        }
    }
    &.btn-white-invisible {
        @include btn-invisible($white);
    }
}

// button-link
.btn-lnk {
    @include btn-lnk;
}

// button pulse
.btn-pulse {
    position: relative;
    overflow: visible;

    &:before {
        content: '';
        display:block;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background-color: inherit;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        transition: opacity .3s, transform .3s;
        -webkit-animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
        animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    }
    &:after {
        display: none;
    }
}
@-webkit-keyframes pulse-animation {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0;
        transform: scale(1.333333);
    }
    100% {
        opacity: 0;
        transform: scale(1.333333);
    }
}
@keyframes pulse-animation {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0;
        transform: scale(1.333333);
    }
    100% {
        opacity: 0;
        transform: scale(1.333333);
    }
}