// encabezado
@mixin text-h1 {
    font-size: 156px;
    line-height: 160px;
}
@mixin text-h2 {
    font-size: 122px;
    line-height: 126px;
}
@mixin text-h3 {
    font-size: 92px;
    line-height: 96px;
}
@mixin text-h4 {
    font-size: 84px;
    line-height: 90px;
}
@mixin text-h5 {
    font-size: 76px;
    line-height: 80px;
}
@mixin text-h6 {
    font-size: 68px;
    line-height: 72px;
}
@mixin text-h7 {
    font-size: 60px;
    line-height: 64px;
}
@mixin text-h8 {
    font-size: 54px;
    line-height: 58px;
}
@mixin text-h9 {
    font-size: 48px;
    line-height: 52px;
}
@mixin text-h10 {
    font-size: 44px;
    line-height: 48px;
}
@mixin text-h11 {
    font-size: 42px;
    line-height: 46px;
}
@mixin text-h12 {
    font-size: 36px;
    line-height: 40px;
}
@mixin text-h13 {
    font-size: 32px;
    line-height: 38px;
}
@mixin text-h14 {
    font-size: 28px;
    line-height: 32px;
}
@mixin text-h15 {
    font-size: 24px;
    line-height: 28px;
}

// parrafo
@mixin text-p1 {
    font-size: 20px;
    line-height: 24px;
}
@mixin text-p2 {
    font-size: 18px;
    line-height: 22px;
}
@mixin text-p3 {
    font-size: 16px;
    line-height: 24px;
}
@mixin text-p4 {
    font-size: 14px;
    line-height: 20px;
}
@mixin text-p5 {
    font-size: 12px;
    line-height: 16px;
}