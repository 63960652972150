$primary: #9C29DE;
$secondary: #47EF56;
$tertiary: #4E4E4E;
$black: #4E4E4E;
$grey4: #495057;
$grey3: #ACB5BD;
$grey2: #DDE2E5;
$grey1: #F8F9FA;
$white: #FFFFFF;
$error: #F44336;
$success: #00D385;
$warning: #FFC107;