@import 'utilities';

.tags {
    margin: -6px;
    @include text-p4;
    @include flex;
    @include flex-wrap;

    a {
        padding: 1px 12px;
        margin: 6px;
        border-radius: 11px;
        background-color: mix($primary, white, 20%);

        &:focus {
            background-color: mix($primary, white, 40%);
        }
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
}