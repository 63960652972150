@import 'utilities';

// .mat-form-field {
//   &.mat-focused {
//     &.mat-primary {
//       .mat-select-arrow {
//         color: $primaryColor;
//       }
//     }
//     &.mat-accent {
//       .mat-select-arrow {
//         color: $secondaryColor;
//       }
//     }
//     &.mat-warn {
//       .mat-select-arrow {
//         color: $danger;
//       }
//     }
//   }
//   .mat-select {
//     &.mat-select-invalid {
//       .mat-select-arrow {
//         color: $danger;
//       }
//     }
//     &.mat-select-disabled {
//       .mat-select-arrow {
//         color: rgba($black, 0.38);
//       }
//     }
//   }
// }

.mat-select-container {
  @include flex;
  @include align-items-center;

  .mat-select-value-text,
  .btn-limpiar-filtro {
    margin-top: 1px;
  }

  .mat-select-value-text {
    padding-right: 26px;
    display: block;
  }

  .mat-select-arrow-wrapper {
    transform: translateY(-3px);
  }

  .mat-select {
    &.matSelectChips {
      margin-left: -5px;
      margin-top: -2px;
      .mat-select-value-text {
        margin-top: 0;
      }
    }
  }

  .btn-limpiar-filtro {
    margin-top: 0;
    position: absolute;
    top: 10px;
    right: 20px;
    
    .mat-icon-button {
      width: 20px;
      height: 20px;
      i {
        line-height: 16px;
      }
    }
  }
}

.mat-select {
  .mat-select-trigger {
    height: 26px;
  }
  .mat-select-value,
  .mat-select-placeholder,
  .mat-select-arrow {
    color: $black
  }
  &.mat-select-disabled {
    .mat-select-value {
      color: rgba($black, 0.38);
    }
  }
  .mat-select-arrow {
    width: 20px;
    height: 20px;
    margin: 0;
    border: 0;

    &:before {
      content: "\e900";
      @include icon;
      font-size: 20px;
    }
  }

  .etiqueta-input { //mat-chip
    & + .etiqueta-input {
      margin-left: 8px;
    }
  }
}

.mat-select-panel {
  padding: 10px;
  background: $white;

  &:not([class*=mat-elevation-z]) {
    box-shadow: 0px 2px 4px -1px rgba($black, 0.2), 0px 4px 5px 0px rgba($black, 0.14), 0px 1px 10px 0px rgba($black, 0.12);
  }

  .mat-option {
    border-radius: 4px;
    color: $black;
    font-size: 16px;

    & + .mat-option {
      margin-top: 1px;
    }

    .mat-option-text {
      @include inline-flex;
      @include align-items-center;
      @include flex-wrap;
    }

    &:hover,
    &:focus {
      &:not(.mat-option-disabled) {
        background: rgba($black, 0.04);
      }
    }
    &.mat-selected {
      &:not(.mat-option-multiple) {
        background: rgba($black, 0.12);

        &:not(.mat-option-disabled) {
          background: rgba($black, 0.04)
        }
      }
    }

    &.mat-active {
      background: rgba($black, 0.04);
      color: $black;
    }
    &.mat-option-disabled {
      color: rgba($black, 0.38);
    }
  }
}

// .mat-primary {
//   .mat-option.mat-selected:not(.mat-option-disabled) {    
//     background-color: mat.get-color-from-palette($mat-primary, 50);
//     color: $primaryColor;
//   }
// }
// .mat-accent {
//   .mat-option.mat-selected:not(.mat-option-disabled) {
//     background-color: mat.get-color-from-palette($mat-secondary, 50);
//     color: $secondaryColor;
//   }
// }
// .mat-warn {
//   .mat-option.mat-selected:not(.mat-option-disabled) {
//     background-color: mat.get-color-from-palette($mat-danger, 50);
//     color: $danger;
//   }
// }

.mat-optgroup-label {
  color: $black;
  /*font-family: $primaryFont;*/
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: normal;
}
.mat-optgroup-disabled {
  .mat-optgroup-label {
    color: rgba($black, 0.38);
  }
}
