@use "sass:math";

@mixin container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    @include center-block;
}
@mixin container-lg {
    @media ($mediaDesktop) {
        width: 1416px;
        @include container;
    }
}
@mixin container-md {
    @media ($mediaTabletH) {
        width: 984px;
        @include container;
    }
}
@mixin container-sm {
    @media ($mediaTabletV) {
        width: 728px;
        @include container;
    }
}
@mixin container-xs {
    @media ($mediaMobile) {
        width: 720px;
        @include container;
    }
}

@mixin row($gap: 0) {
    @include flex;
    @include flex-wrap;

    @if $gap != 0 {
        margin-left: math.div($gap, -2);
        margin-right: math.div($gap, -2);
    }
    > * {
        width: 100%;
        @include flex-grow(0);
        @include flex-shrink(0);
        @include flex-basis(auto);

        @if $gap != 0 {
            padding-left: math.div($gap, 2);
            padding-right: math.div($gap, 2);
        }
    }
}

@function getWidth($cols) {
    $result: math.div($cols * 100%, 12);
    @return $result;
}
@mixin col($cols) {
    width: getWidth($cols);
}
@mixin inset($cols) {
    margin-left: -1 * getWidth($cols);
}
@mixin offset($cols) {
    margin-left: getWidth($cols);
}