@import 'utilities';

.table {
    width: 100%;
    border-bottom: 2px solid $grey2;
    border-spacing: 0;

    tr {

        > * {
            padding: 10px 20px;
            vertical-align: middle;
        }
        > th {
            border-bottom: 1px solid $grey2;
            color: $grey3;
            @include text-p5;
            font-weight: 700;
            text-transform: uppercase;

            &:not(.text-center):not(.text-right) {
                text-align: left;
            }
        }
        > td {
            border-top: 1px solid $grey2;
            @include text-p4;
            font-weight: 300;

            strong,
            b {
                font-weight: 700;
            }
        }
    }
}

.tableResponsive {
    overflow-x: auto;

    .table {

        > tr {

            > td {

                .tableResponsive-toggle {
                    position: absolute;
                    left: -16px;
                    display: none;
    
                    button {
                        @include btn;
                        @include btn-contained($tertiary);
                        @include btn-icon;
                        width: 32px;
                        height: 32px;
                        font-size: 24px;
                        @include icon;

                        &:before {
                            content: "\eea4";
                        }
                    }
                }
                .tableResponsive-label {
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid $grey2;
                    margin-left: -12px;
                    margin-right: -12px;
                    margin-bottom: 8px;
                    color: $grey3;
                    @include text-p5;
                    font-weight: 700;
                    text-transform: uppercase;
                    display: none;
                }
            }
            &.tableResponsive-on {

                > td {

                    .tableResponsive-toggle {

                        button {

                            &:before {
                                content: "\e95c";
                            }
                        }
                    }
                }
            }
        }
    }
    &.tableResponsive-collapsed {
        overflow-x: visible;

        .table {

            thead {
                display: none;
            }
            tbody {

                > tr {
                    padding-left: 20px;
                    border-bottom: 1px solid $grey2;
                    position: relative;
                    @include flex;
                    @include flex-wrap;

                    &:last-child {
                        border-bottom: 0;
                    }
                    > td {
                        padding: 10px 12px;
                        border-top: 0;

                        .tableResponsive-toggle {
                            display: block;
                        }
                        &.tableResponsive-n1 {
                            width: 100%;
                            padding-bottom: 0;
                            @include flex-grow(0);
                            @include flex-shrink(0);
                            @include flex-order(1);
                        }
                        &.tableResponsive-n2 {
                            width: 50%;
                            @include flex-grow(0);
                            @include flex-shrink(0);
                            @include flex-order(2);
                            @include align-self-center;
                        }
                        &.tableResponsive-n3 {
                            @include flex-grow(1);
                            @include flex-shrink(1);
                            @include flex-order(3);
                            display: none;
                        }
                    }
                    &.tableResponsive-on {

                        .tableResponsive-n3,
                        .tableResponsive-label {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}